import { links } from './links';

const commonStyles = {
	position: 'relative',
	fontWeight: 'bold',
	lineHeight: 1,
	transition: 'all 0.3s ease',
	textTransform: 'uppercase',
	borderStyle: 'solid',
	borderWidth: 2,
	borderRadius: 1,
	'&:active': {
		top: '2px',
	},
};

export const buttonOutline = {
	primaryEmphasis: {
		...commonStyles,
		color: 'primary',
		bg: 'background',
		borderColor: 'primary',
		'&:hover': {
			bg: 'emphasis',
		},
	},
	emphasis: {
		...commonStyles,
		color: 'primary',
		bg: 'emphasis',
		borderColor: 'primary',
		'&:hover': {
			color: 'emphasis',
			bg: 'primary',
			borderColor: 'primary',
		},
	},
	primary: {
		...commonStyles,
		color: 'primary',
		bg: 'background',
		borderColor: 'primary',
		'&:hover': {
			bg: 'primary',
			color: 'white',
		},
	},
	secondary: {
		...commonStyles,
		color: 'secondary',
		bg: 'background',
		borderStyle: 'solid',
		borderColor: 'secondary',
		'&:hover': {
			bg: 'secondary',
			color: 'primary',
		},
	},
};

export const buttons = {
	primaryEmphasis: {
		...commonStyles,
		color: 'white',
		bg: 'primary',
		borderColor: 'transparent',
		'&:hover': {
			color: 'primary',
			bg: 'emphasis',
			borderColor: 'primary',
		},
	},
	primary: {
		...commonStyles,
		color: 'background',
		bg: 'primary',
		borderColor: 'transparent',
		'&:hover': {
			color: 'primary',
			bg: 'background',
			borderColor: 'primary',
		},
	},
	secondary: {
		...commonStyles,
		borderColor: 'transparent',
		color: 'background',
		bg: 'secondary',
		'&:hover': {
			color: 'secondary',
			bg: 'background',
			borderStyle: 'solid',
			borderColor: 'secondary',
		},
	},
	icon: {
		bg: 'transparent',
		color: 'inherit',
		textDecoration: 'none',
		height: 'auto',
		lineHeight: 1,
		padding: 0,
		overflow: 'hidden',
		fontSize: '18px',
		'&:hover': {
			textDecoration: 'none',
		},
	},
	link: {
		bg: 'transparent',
		...links.underlined,
	},
};

export const buttonSizes = {
	small: {
		fontSize: 1,
		px: 5,
		py: 2,
	},
	default: {
		fontSize: 'inherit',
		lineHeight: 'inherit',
		px: 8,
		py: 4,
	},
	large: {
		fontSize: 3,
		px: 10,
		py: 5,
	},
	link: {
		p: 0,
	},
};
