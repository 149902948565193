export const forms = {
	input: {
		borderRadius: 1,
		border: (theme) => `2px solid ${theme.colors.gray300}`,
		px: [5],
		py: [4],
		color: 'gray800',
		'&:placeholder': {
			color: 'gray500',
		},
		'&:active, &:focus': {
			borderColor: (theme) => theme.colors.gray700,
		},
		'&[aria-invalid="true"]': {
			borderColor: (theme) => theme.colors.error,
		},
	},
};
