/** @jsx jsx */
import { CacheProvider } from '@emotion/core';
import { cache } from 'emotion';
import { jsx, ThemeProvider } from 'theme-ui';
import { base } from '../themes';
import Meta from '../components/Meta';
import { AuthProvider } from '../hooks/auth';
import '../../public/css/critical.css';
import { FirebaseAppProvider } from '../hooks/firebase';

function MyApp({ Component, pageProps }) {
	return (
		<FirebaseAppProvider>
			<AuthProvider>
				<CacheProvider value={cache}>
					<ThemeProvider theme={base}>
						<Meta />
						<Component {...pageProps} />
					</ThemeProvider>
				</CacheProvider>
			</AuthProvider>
		</FirebaseAppProvider>
	);
}

export default MyApp;
