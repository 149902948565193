import { css } from 'emotion';

const getMaxWidth = (px) => px - 1;

export const mqSizes = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1440,
};

// Media queries are implemented as per "Mobile first" approach.
export default Object.keys(mqSizes).reduce((acc, label) => {
	acc[label + 'Down'] = (...args) => css`
		@media screen and (max-width: ${getMaxWidth(mqSizes[label])}px) {
			${css(...args)};
		}
	`;

	acc[label + 'Up'] = (...args) => css`
		@media screen and (min-width: ${mqSizes[label]}px) {
			${css(...args)};
		}
	`;

	acc[label] = (...args) => {
		const keyIdx = Object.keys(mqSizes).indexOf(label);
		const minKey = keyIdx - 1;
		const minMedia = minKey < 0 ? null : mqSizes[minKey];
		const maxMedia = getMaxWidth(mqSizes[keyIdx]);
		if (!minMedia) {
			return css`
				@media screen and (max-width: ${maxMedia}px) {
					${css(...args)};
				}
			`;
		} else {
			return css`
				@media screen and (min-width: ${minMedia}px) and (max-width: ${maxMedia}px) {
					${css(...args)};
				}
			`;
		}
	};
	return acc;
}, {});
