import colors from '../colors';

export const loaders = {
	light: {
		color: colors.white,
		bg: colors.black,
	},
	dark: {
		color: colors.black,
		bg: colors.white,
	},
};
