import media from '../../utils/media-queries';
import * as componentStyles from './components';
import colors from './colors';

const heading = {
	color: 'headingText',
	fontFamily: 'heading',
	lineHeight: 'heading',
	fontWeight: 'heading',
};

export const breakpoints = ['576px', '768px', '992px', '1200px', '1440px'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

const space = [0, 4, 8, 10, 12, 16, 20, 24, 32, 42, 48, 64, 72, 128, 256, 512];
space.xs = space[2];
space.sm = space[4];
space.md = space[6];
space.lg = space[8];
space.xlg = space[10];

const shadows = {
	sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
	md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
	'dark-lg':
		'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px',
	lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
	xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
	'2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
	outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
	inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
	none: 'none',
};

export const base = {
	breakpoints,
	media,
	maxContainerWidth: breakpoints.xl,
	space,
	shadows,
	colors,
	fonts: {
		body: "'Montserrat', sans-serif",
		heading: "'Open Sans', sans-serif",
		monospace: 'Menlo, monospace',
	},
	fontSizes: [12, 14, 16, 18, 20, 24, 32, 36, 40, 44, 52, 60, 96],
	fontWeights: {
		body: 400,
		semi: 500,
		heading: 800,
		bold: 700,
	},
	lineHeights: {
		body: 1.5,
		heading: 1.125,
	},
	styles: {
		root: {
			fontFamily: 'body',
			lineHeight: 'body',
			fontWeight: 'body',
		},
		h1: {
			...heading,
			fontSize: [7, 8, 9, 10],
		},
		h2: {
			...heading,
			fontSize: [6, 7, 8],
		},
		h3: {
			...heading,
			fontSize: [5, 5, 6],
		},
		h4: {
			...heading,
			fontSize: [4, 4, 5],
		},
		h5: {
			...heading,
			fontSize: [4, 4, 5],
		},
		h6: {
			...heading,
			fontSize: [4, 4, 5],
		},
		p: {
			color: 'text',
			fontFamily: 'body',
			fontWeight: 'body',
			lineHeight: 'body',
		},
		a: {
			color: 'primary',
			textDecoration: 'none',
			lineHeight: 1,
			'&:focus, &:hover': {
				textDecoration: 'underline',
			},
		},
		pre: {
			fontFamily: 'monospace',
			overflowX: 'auto',
			code: {
				color: 'inherit',
			},
		},
		code: {
			fontFamily: 'monospace',
			fontSize: 'inherit',
		},
		table: {
			width: '100%',
			borderCollapse: 'separate',
			borderSpacing: 0,
		},
		th: {
			textAlign: 'left',
			borderBottomStyle: 'solid',
		},
		td: {
			textAlign: 'left',
			borderBottomStyle: 'solid',
		},
		img: {
			maxWidth: '100%',
		},
		hr: {
			color: 'gray100',
			my: 'sm',
		},
	},
	...componentStyles,
};

export default base;
