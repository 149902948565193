const colors = {
	emphasis: '#ffd200',
	white: '#FFFFFF',
	black: '#000000',
	red: '#CC0000',
	orange: '#F68013',
	green: '#00AA00',
	blue: '#007aff',
	gray50: '#F6F6F6',
	gray100: '#EEEEEE',
	gray200: '#E2E2E2',
	gray300: '#CBCBCB',
	gray400: '#AFAFAF',
	gray500: '#757575',
	gray600: '#545454',
	gray700: '#333333',
	gray800: '#1F1F1F',
	gray900: '#141414',
	transparent: 'transparent',
	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.80)',
		900: 'rgba(255, 255, 255, 0.92)',
	},
	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.80)',
		900: 'rgba(0, 0, 0, 0.92)',
	},
};

export default {
	...colors,
	text: colors.gray800,
	headingText: colors.black,
	background: colors.white,
	backgroundGray: colors.gray50,
	backgroundDark: colors.gray800,
	primary: colors.black,
	secondary: colors.gray600,
	muted: colors.gray50,
	error: colors.red,
	success: colors.green,
	warning: colors.orange,
	info: colors.blue,
};
