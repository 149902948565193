export const links = {
	nav: {
		color: 'inherit',
		mx: 2,
		fontWeight: 'semi',
		textDecoration: 'none',
		lineHeight: '42px',
		'&:focus, &:hover': {
			textDecoration: 'underline',
		},
	},
	underlined: {
		color: 'inherit',
		fontWeight: 'bold',
		textDecoration: 'underline',
		'&:focus, &:hover': {
			textDecoration: 'none',
		},
	},
};
