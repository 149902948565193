import React from 'react';
import Head from 'next/head';

const makeTitle = (title, name) => (title.startsWith(name) ? title : `${title} – ${name}`);

const Meta = ({
	color = '#ffffff',
	name = 'Togglez',
	title = 'Togglez - Feature toggles(aka feature flags) and remote configs a service',
	description = 'Simple cross-platform feature toggles, feature flags and remote configuration management from software teams',
	// image = 'https://2019.hackpenn.com/card.png',
	image = '',
	url = 'https://togglez.com',
	logo = '',
	twitterHandle = '@togglez5',
	twitterLink = 'https://twitter.com/togglez5',
	instagramLink = 'https://www.instagram.com/togglez.com',
	facebookLink = 'https://www.facebook.com/togglez.com',
}) => (
	<Head>
		<meta key="og_locale" property="og:locale" content="en_US" />
		<meta key="og_type" property="og:type" content="website" />
		<meta key="og_site" property="og:site_name" content={name} />
		<meta key="tw_site" name="twitter:site" content={twitterHandle} />
		<title key="title">{makeTitle(title, name)}</title>
		<meta key="og_title" property="og:title" content={makeTitle(title, name)} />
		<meta key="tw_title" name="twitter:title" content={makeTitle(title, name)} />
		{description && (
			<>
				<meta key="desc" name="description" content={description} />
				<meta key="og_desc" property="og:description" content={description} />
				<meta key="tw_desc" name="twitter:description" content={description} />
			</>
		)}
		{image && (
			<>
				<meta key="og_img" property="og:image" content={image} />
				<meta key="tw_card" name="twitter:card" content="summary_large_image" />
				<meta key="tw_img" name="twitter:image" content={image} />
			</>
		)}
		<meta name="application-name" content="Togglez - Feature toggles(flags) as a service" />
		<link rel="apple-touch-icon" sizes="57x57" href="/images/favicon/apple-icon-57x57.png" />
		<link rel="apple-touch-icon" sizes="60x60" href="/images/favicon/apple-icon-60x60.png" />
		<link rel="apple-touch-icon" sizes="72x72" href="/images/favicon/apple-icon-72x72.png" />
		<link rel="apple-touch-icon" sizes="76x76" href="/images/favicon/apple-icon-76x76.png" />
		<link
			rel="apple-touch-icon"
			sizes="114x114"
			href="/images/favicon/apple-icon-114x114.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="120x120"
			href="/images/favicon/apple-icon-120x120.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="144x144"
			href="/images/favicon/apple-icon-144x144.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="152x152"
			href="/images/favicon/apple-icon-152x152.png"
		/>
		<link
			rel="apple-touch-icon"
			sizes="180x180"
			href="/images/favicon/apple-icon-180x180.png"
		/>
		<link
			rel="icon"
			type="image/png"
			sizes="192x192"
			href="/images/favicon/android-icon-192x192.png"
		/>
		<link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
		<link rel="icon" type="image/png" sizes="96x96" href="/images/favicon/favicon-96x96.png" />
		<link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
		<link rel="manifest" href="/images/favicon/manifest.json" />
		<meta name="msapplication-TileColor" content={color} />
		<meta name="msapplication-TileImage" content="/images/favicon/ms-icon-144x144.png" />
		<title>Togglez - Feature toggles(aka feature flags) as a service</title>

		<script
			key="ld_json"
			type="application/ld+json"
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					'@context': 'https://schema.org',
					'@type': 'Organization',
					name,
					url,
					logo,
					sameAs: [twitterLink, instagramLink, facebookLink],
					contactPoint: [
						{
							'@type': 'ContactPoint',
							email: 'contact@togglez.com',
							contactType: 'customer support',
							url,
						},
					],
				}),
			}}
		/>
	</Head>
);

export default Meta;
